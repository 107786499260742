<template>
  <AbstractFilter
    :on-filter="filterSources"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="source_new"
    filter-panel-class="filter-panel-source"
  >
    <Input
      v-model="filter.name"
      id="filter_name"
      :label="$t('sources.name')"
    />
    <Input
      v-model="filter.customText"
      id="filter_customText"
      :label="$t('sources.customText')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      label="Id"
    />
  </AbstractFilter>
</template>

<script>

import AbstractFilter from '@/components/filter/AbstractFilter'
import Input from '@/components/form/inputs/Input'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'SourceFilter',
  components: {
    AbstractFilter,
    Input
  },
  data () {
    return {
      isLoading: false,
      filter: { id: '', name: '', customText: '' },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SOURCE_PERMISSIONS
    }
  },
  methods: {
    filterSources () {
      this.$store.commit('source/setPage', 1)
      this.$store.commit('source/setFilter', this.filter)
      this.$store.dispatch('source/fetch')
    },
    resetFilter () {
      this.$store.commit('source/resetFilter')
      this.filter = this.$store.getters['source/filter']
      this.$store.dispatch('source/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['source/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-source {
  @include bp(7) {
    grid-template-columns: 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
