<template>
  <section>
    <SourceFilter />
    <Preloader v-if="callingAPI" />
    <SourceTable />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import SourceFilter from '@/components/filter/FilterSource'
import SourceTable from '@/components/table/SourceTable'

export default {
  name: 'SourceListView',
  components: {
    SourceTable,
    Preloader,
    SourceFilter
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  }
}
</script>
